.backButtonContainer {
    margin: 20px;
}

.arrowIcon {
    font-size: 25px;
    cursor: pointer;
}

.arrowIcon:hover{
    font-size: 45px;
}