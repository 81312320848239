.formContainer, .formWrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
}

.fieldsContainer {
    display: flex;
    flex-direction: row;
    /* width: 100%; */
    height: auto;
    justify-content: center;
    align-items: center;
}

.alpozoLogo {
    margin-top: 20px;
}

.buttonForm {
    border: 1px solid !important;
    margin-top: 10px !important;
}