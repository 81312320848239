.containerForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
  height: 100%;
  width: 100%;
}

.input {
  width: 100%;
}
::placeholder {
  color: var(--color-grey-text) !important;
}

.bottomContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttonForm {
  width: 60% !important;
  min-width: 120px !important;
  min-height: 48px !important;
  border-radius: 30px !important;
  font-family: "Montserrat-Regular" !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  color: var(--color-white) !important;
  background-color: var(--color-secondary) !important;
}
