.containerForm{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.checkboxContainer {
    display: flex;
    padding: 20px;
}