@import url(https://fonts.googleapis.com/css?family=Signika:700,300,600);

/* .titleContainer {
  height: 100%;
} */
/* .titleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  text-align: center;
  overflow: hidden;
} */

.title {
  font-size: 3em;
  /* font:bold 7.5vw/1.6 'Signika', sans-serif; */
  user-select: none;
}

.title span {
  display: inline-block;
  animation: float 0.2s ease-in-out infinite;
}
@keyframes float {
  0%,
  100% {
    transform: none;
  }
  33% {
    transform: translateY(-1px) rotate(-2deg);
  }
  66% {
    transform: translateY(1px) rotate(2deg);
  }
}
.titleContainer:hover span {
  animation: bounce 0.6s;
}
@keyframes bounce {
  0%,
  100% {
    transform: translate(0);
  }
  25% {
    transform: rotateX(20deg) translateY(2px) rotate(-3deg);
  }
  50% {
    transform: translateY(-20px) rotate(3deg) scale(1.1);
  }
}

span:nth-child(4n) {
  color: hsl(354, 0%, 0%);
  text-shadow: 1px 1px hsl(0, 83%, 57%), 2px 2px hsl(0, 83%, 57%),
    3px 3px hsl(0, 83%, 57%), 4px 4px hsl(0, 83%, 57%);
}
span:nth-child(4n-1) {
  color: hsl(0, 83%, 57%);
  text-shadow: 1px 1px hsl(354, 0%, 0%), 2px 2px hsl(354, 0%, 0%),
    3px 3px hsl(354, 0%, 0%), 4px 4px hsl(354, 0%, 0%);
}
span:nth-child(4n-2) {
    color: hsl(0, 83%, 57%);
    text-shadow: 1px 1px hsl(354, 0%, 0%), 2px 2px hsl(354, 0%, 0%),
      3px 3px hsl(354, 0%, 0%), 4px 4px hsl(354, 0%, 0%);
}
span:nth-child(4n-3) {
  color: hsl(0, 0%, 0%);
  text-shadow: 1px 1px hsl(0, 83%, 57%), 2px 2px hsl(0, 83%, 57%),
    3px 3px hsl(0, 83%, 57%), 4px 4px hsl(0, 83%, 57%);
}

.title span:nth-child(2) {
  animation-delay: 0.05s;
}
.title span:nth-child(3) {
  animation-delay: 0.1s;
}
.title span:nth-child(4) {
  animation-delay: 0.15s;
}
.title span:nth-child(5) {
  animation-delay: 0.2s;
}
.title span:nth-child(6) {
  animation-delay: 0.25s;
}
.title span:nth-child(7) {
  animation-delay: 0.3s;
}
.title span:nth-child(8) {
  animation-delay: 0.35s;
}
.title span:nth-child(9) {
  animation-delay: 0.4s;
}
.title span:nth-child(10) {
  animation-delay: 0.45s;
}
.title span:nth-child(11) {
  animation-delay: 0.5s;
}
.title span:nth-child(12) {
  animation-delay: 0.55s;
}
.title span:nth-child(13) {
  animation-delay: 0.6s;
}
.title span:nth-child(14) {
  animation-delay: 0.65s;
}

.x {
    border: 1px solid #000000;
    padding: 5px;
    margin: 5px;
}