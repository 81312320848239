.filterContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: sans-serif;
  padding: 2rem 4rem;
  height: 100%;
}

.listContainer {
    margin-top: 50px;
}
.titleContainer {
  position: relative;
  width: 90%;
  max-width: 500px;
  padding: 2em;
  margin: 1.5em auto;
  border: 3px solid rgba(0, 0, 0, 0.08);
}

.filterElement {
  /* padding: 0.5rem 0; */
  padding: 15px;
  font-size: 60px;
  /* list-style-type: none; */
}

.filterLink {
  color: #000000;
  text-decoration: none;
  position: relative;
  padding: 2px 2px;
  transition: all 0.2s ease-out;
}
.filterLink:after {
  content: " ";
  width: 100%;
  height: 1px;
  background: #000000;
  position: absolute;
  right: 0;
  bottom: 0;
  transition: all 0.2s ease-out;
}
.filterLink:before {
  content: " ";
  width: 4px;
  height: 4px;
  background: #000000;
  position: absolute;
  right: -4px;
  bottom: 0;
  opacity: 0;
}
.filterLink:hover {
  background: rgba(20, 67, 255, 0.1);
  transition: all 0.1s ease-out;
  padding: 2px 8px;
}
.filterLink:hover:after {
  width: 0px;
  transition: all 0.1s ease-out;
}
.filterLink:hover:before {
  opacity: 1;
  animation: blink 0.4s infinite 0.1s;
  transition: all 0.1s ease-out;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.filterTitle {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  font-size: 40px;
  color: #080808;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.filterTitle {
  position: relative;
  font-weight: 700;
  letter-spacing: 0px;
  text-transform: uppercase;
  text-align: center;
  margin: auto;
  border: 2px solid #222;
  padding: 5px 11px 3px 11px;
}

.filterTitle:before,
.filterTitle:before {
  background-color: #c50000;
  position: absolute;
  content: "";
  height: 7px;

  width: 7px;
  border-radius: 50%;
  bottom: 12px;
}

.filterTitle:before {
  left: -20px;
}

.filterTitle:after {
  right: -20px;
}
