.containerTextField {
    width: 100%;
    height: 70px;
    position: relative;
    border: 0;
  }

  .textFieldGroupError {
    height: 25px;
    text-align: left;
    color: var(--color-red);
    font-size: 13px;
    margin-top: 3px;
    width: 100%;
  }

  .passwordErrors {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top:5px;
  }

  .containerTextAndIcon {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    font-family: 'Montserrat-Regular';
    font-size: 14px;
  }

  .completed {
    color: var(--color-secondary) !important;
  }

  .incompleted {
    color: var(--color-grey-text);
  }