.containerTextField {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-content: center;
    text-align: center;
    background-color: #1C2833;
    width: 100%;
    margin: 10px;
    padding: 30px;
}

.labelText {
    font-family: 'Roboto', sans-serif;
    font-size: 30px;
    font-weight: 900;
    color: #D5D8DC ;
}

input[type='date'] {
    align-items: center;
    cursor: pointer;
}