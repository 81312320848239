.divOption {
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px;
    padding: auto;
    margin: 5%;
    cursor: pointer;
    min-height: 270px;
    transition: transform .2s; /* Animation */
}

.filters {
    border-radius: 20%;
}

.textBox {
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    text-align: center;
    font-size: 40px;
    padding: 30px;
    min-width: 200px;
}

.qr {
    border-radius: 10%;
}
/* EFECTO DE ZOOM */

.divOption:hover {
    background-color: #1C2833;
    transform: scale(1.2); /* (110% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.divOption:hover .textBox {
    color: #D5D8DC ;
}